<template>
  <div class="box">
    <div class="content" ref="content">
      <!-- 用户隐私政策-红牛1.19-法务版.docx -->
      <h2>用户隐私政策</h2>
      <p>
        <span class="bold"
          >请您仔细阅读本隐私政策。为方便您登录、参与本次活动，您在参与本次活动时，红牛公司</span
        >（以下简称“我们”或主办方）<span class="bold"
          >会收集和使用您的相关信息。您同意：您已满十四周岁或在监护人陪同下使用，且已完全理解并接受本隐私政策的全部条款和内容。如果您不同意本隐私政策，您可以不参与使用我们的活动。</span
        >
      </p>
      <p>
        主办方以此声明对消费者隐私保护的许诺。我们可能适时修订本隐私政策的条款，如可能造成您在本隐私政策下权利的实质减少或扩大收集、使用信息的范围等重要规则变更时，我们将在修订生效前通过在主页弹窗提示的方式再次请您确认。在该种情况下，若您继续使用我们的服务，即表示同意受经修订的本隐私政策的约束。
      </p>
      <p>
        本政策适用于我们本次活动的抽奖流程、实物奖领取流程及现金红包发放流程，包括但不限于我们提供的H5网站、小程序、SDK、API、插件、组件、代码、工具以及不断创新研发的活动及服务，均适用本隐私条款。
      </p>
      <p class="bold">
        需要特别说明的是，除红牛奖品之外的第三方活动电子奖权益的兑奖、领取等全部事项均由第三方单独提供服务，该服务适用其向您另行说明的个人信息收集使用规则。我们无法了解或控制其收集和使用等处理数据的目的、方式和范围，在您已查看并接受其服务条款和隐私政策等法律声明之前，请谨慎访问或使用其服务。
      </p>
      <p>
        为确保我们的服务合乎法律法规、国家标准、行业标准及与您达成的服务约定，我们将遵守亦恳请您遵守法律法规等规范性文件中关于数据安全和个人信息保护的相关规定。同时，为方便您了解我们的数据安全和个人信息保护政策，在本部分，我们对数据的收集、使用、存储、共享等处理行为中分别采取的保护措施等进行了详细说明。
      </p>
      <p class="bold">一、数据的收集：</p>
      <p>
        我们创办本次活动时，将会获取、存储和使用您提供以及您授权的信息，以便本次活动高效运营为您提供更优的效果。您已知晓并理解，数据的获取受您所处的网络环境、隐私设置以及您所使用的第三方产品或服务等多种因素的影响。我们收集信息是为了更好、更优的为您提供更好的活动体验。我们收集数据的方式如下：
      </p>
      <p class="bold">（一）数据的来源</p>
      <p class="bold">1.您向我们提供的数据</p>

      <p>
        您在参加我们的活动时，会向我们提供相关数据。例如当您参与扫码活动时，我们可能会需要您开启相机、摄像头、相册权限，我们可能会获取您相册内的图片、视频信息，以帮助您顺利的完成扫码；当您参与抽奖活动得到实物奖，可能您需要提交您的电话号码、身份证号、收件地址及收件人姓名等相关数据。基于相关数据安全法律法规的规定，请您保证所提供数据的合法合规性。基于新出台的法律法规要求或活动服务所必须，我们可能需要您补充提供或您主动更新您提供的数据及授权。如果您不提供或者您不继续授权我们使用这些数据，将可能影响到您继续参与我们的活动或影响领取奖品。
      </p>
      <p class="bold">2.在您参与我们活动过程中收集的数据</p>
      <p>
        我们会在您参与活动时收集数据。在您参与我们的活动时，您已充分了解我们的活动内容，我们将基于合法、正当、必要、明确的目的收集、存储、处理来自于您的相关数据。请您了解，这些数据是我们提供服务和保障活动正常进行所必须收集的基本数据。
      </p>
      <p class="bold">3.第三方数据来源</p>
      <p>
        我们在与第三方开展业务合作时，可能会获取第三方向我们提供的与您相关的合法授权的数据。出于支持本次活动的必要，我们亦会考虑将此类数据用于对您的本次服务中。我们不会向第三方主动共享或转让您授权我们的数据信息。
      </p>
      <p class="bold">4.数据收集和使用获得授权的例外</p>
      <p>根据相关法律法规规定，以下情形中我们收集您的数据无需征得授权同意：</p>
      <p>（1）与个人信息控制者履行法律法规规定的义务相关的；</p>
      <p>（2）与国家安全、国防安全直接相关的；</p>
      <p>（3）与公共安全、公共卫生、重大公共利益直接相关的；</p>
      <p>（4）与刑事侦查、起诉、审判和判决执行等直接相关的；</p>
      <p>
        （5）出于维护个人信息主体或其他个人的生命、财产等重大合法权益但又很难得到本人授权同意的；
      </p>
      <p>（6）所涉及的个人信息是个人信息主体自行向社会公众公开的；</p>
      <p>（7）根据个人信息主体要求签订和履行合同所必需的；</p>
      <p>
        注∶个人信息保护政策的主要功能为公开个人信息控制者收集、使用个人信息范围和规则，不宜将其视为合同。
      </p>
      <p>
        （8）从合法公开披露的信息中收集个人信息的，如合法的新闻报道、政府信息公开等渠道；
      </p>
      <p>
        （9）维护所提供产品或服务的安全稳定运行所必需的，如发现、处置产品或服务的故障；
      </p>
      <p>（10）个人信息控制者为新闻单位，且其开展合法的新闻报道所必需的；</p>
      <p>
        （11）个人信息控制者为学术研究机构，出于公共利益开展统计或学术研究所必要，且其对外提供学术研究或描述的结果时，对结果中所包含的个人信息进行去标识化处理的。
      </p>
      <p>（12）法律、法规规定的其他情形。</p>
      <p class="bold">(二)数据的类型</p>
      <p>
        我们所收集的数据类型主要为日志信息。日志信息指您参与我们的活动时，系统可能会通过SDK、API、插件、组件、代码、工具、cookieweb
        beacon或其他方式采集您的数据，包括：
      </p>
      <p></p>
      <p>
        （1）设备或软件信息，例如移动设备或您参与我们活动的其他程序所提供的配置信息、IP地址和移动设备所用的版本和设备识别码，包括硬件型号、设备ID、操作系统类型、软件名称等在内的描述个人常用设备基本情况的信息；
      </p>
      <p>
        （2）设备所在位置相关信息。如果您在安装及/或使用过程设备中拒绝收集此类信息的，我们并不会收集此类的信息；
      </p>
      <p>
        （3）您在使用第三方商业接口服务时搜索和浏览的信息，例如使用的网页搜索词语、访问的社交媒体页面url_地址；
      </p>
      <p>（4）通过我们的服务共享的内容所包含的信息(元数据)。</p>
      <p class="bold">
        请注意，单独的日志信息等是无法识别特定自然人身份的信息。除非将这类非个人信息与其他信息结合用于识别特定自然人身份，或者将其与个人信息结合使用，那么在结合使用期间，这类非个人信息将被视为个人信息。我们会将该类个人信息做匿名化或去标识化处理(已取得授权或法律法规另有规定的情况除外)。
      </p>
      <p>（5）三方SDK或者接口返回您的id、国家、省份、城市等信息。</p>
      <p class="bold">二、数据的使用</p>
      <p class="bold">
        我们收集这些数是为了向您更好提供活动支持，为了实现这一目的，我们会把您的信息用于下列用途︰
      </p>
      <p>（1）收集数据作身份核证及记录；</p>
      <p>（2）保存通讯联络资料；</p>
      <p>（3）回应该消费者或以该消费者名义提出的查询；</p>
      <p>
        （4）用于社会化洞察与分析等全方位市场营销服务，并维护、改进这些服务；
      </p>
      <p>
        （5）我们可能会将合法收集的数据进行去标识化的研究、统计分析和预测，发布在特定行业内、特定区域和市场等方面的行业分析报告或行业白皮书。行业分析报告或行业白皮书将以不可识别个人信息的聚合形式表现；
      </p>
      <p>
        （6）我们可能会对收集的信息进行去标识化的研究、统计分析和预测，用于改善现有活动，为未来活动提供支撑；
      </p>
      <p>
        （7）在我们提供服务时，用于身份验证、客户服务、安全防范、诈骗监测、存档和备份用途，确保我们的活动安全，并依法采取必要的记录、审计、分析、处置措施。
      </p>
      <p class="bold">三、我们如何使用Cookie和同类技术</p>
      <p>
        Cookie和同类技术是互联网中的通用常用技术。当您参与我们的活动时，我们可能会使用相关技术向您的设备发送一个或多个Cookie或匿名标识符，以收集和存储您访问、使用我们网站或系统时的信息。我们使用Cookie和同类技术主要为了保障产品与服务的安全、高效运转，改善产品服务及用户体验。
      </p>
      <p>
        如果您的浏览器或浏览器附加服务允许，您可修改对Cookie的接受程度或拒绝我们的Cookie。大多数浏览器均为用户提供了清除浏览器缓存数据的功能，您可以在浏览器设置功能中进行相应的数据清除操作。但请注意，如果停用Cookie，您可能无法享受相对更佳的服务体验。
      </p>
      <p class="bold">四、数据的共享、转让、公开披露</p>
      <p class="bold">（一）数据的共享</p>
      <p>我们在以下情况中进行数据共享∶</p>
      <p>
        <span class="bold">（1）在法定情形下的共享︰</span
        >我们可能会根据法律法规规定、诉讼争议解决需要，或按行政、司法机关依法提出的要求，对外共享数据。
      </p>
      <p>
        <span class="bold">（2）与关联公司间共享∶</span
        >为更好地为您提供产品和服务，我们将与我们的集团公司或关联公司共享数据。我们及我们的集团公司或关联公司将按照法律法规及不低于本政策所要求的标准保护与您服务相关的数据信息。
      </p>
      <p>
        <span class="bold">（3）与合作伙伴共享：</span
        >因活动及技术需要，我们需授权合作伙伴给我们提供技术服务，我们可能与我们业务的合作伙伴（代表我们为您提供某些服务或履行职能的服务机构，包括但不限于北京尚杰智选科技有限公司及其运营的平台）共享数据，这些支持包括提供技术基础设施服务、提供客户服务支持或进行行业研究等。我们会与合作伙伴约定严格的数据保护措施，令其按照不低于本政策以及其他任何相关的保密和安全措施来处理数据。
      </p>
      <p>
        请知悉，根据适用的法律，<span class="bold"
          >若我们对个人信息采取技术措施和其他必要措施进行去标识化或匿名化处理，使得数据接收方无法重新识别特定个人且不能复原，则此类处理后数据的共享无需另行向您通知并征得您的同意。</span
        >
      </p>
      <p class="bold">（二）数据的转让</p>
      <p>
        我们不会向除上述合作的第三方之外的机构或第三方转让您的个人信息，除非以下情形︰
      </p>
      <p>（1）获得消费者的同意。</p>
      <p>
        （2）在涉及合并、收购或破产清算时，如涉及到个人信息转让，我们会在要求新的持有您最终端个人信息的公司、组织继续受此隐私政策的约束，否则我们将要求该公司、组织重新向消费者征求授权同意。
      </p>
      <p class="bold">（三）数据的公开披露</p>
      <p>我们仅会在以下情况下，公开披露终端的个人信息∶</p>
      <p>
        （1）基于您的主动选择及其他单独同意的情况下，我们可能会公开披露您的个人信息；
      </p>
      <p>
        （2）基于法律的披露∶在法律、法律程序、诉讼或政府主管部门强制性要求的情况下，我们可能会公开披露消费者的个人信息；
      </p>
      <p>
        （3）在紧急情况下，经合理判断是为了保护我们、我们的消费者或其他人的重要合法权益。
      </p>
      <p class="bold">五、数据的存储</p>
      <p>
        原则上，我们在中华人民共和国境内运营中收集和产生的数据将按照法律法规规定存储于中国境内，不会向境外转移。
      </p>
      <p>
        我们将采用安全措施存储数据，数据的存储期限严格按照法律法规规定（如根据数据类型、用途等区分保存期限6个月、2年、3年等），并在满足服务目的后的合理期限内予以删除或匿名化处理，除非需要延长保留期或受到法律的允许。
      </p>
      <p class="bold">六、我们如何保护个人信息的安全</p>
      <p>
        6.1我们非常重视您个人信息的安全，将努力采取合理的安全措施(包括技术方面和管理方面)来保护您的个人信息，防止您提供的个人信息被不当使用或在未经授权的情况下被访问、公开披露、使用、修改、损坏、丢失或泄漏。
      </p>
      <p>
        6.2我们会使用不低于行业标准的加密技术、匿名化处理及相关合理可行的手段保护您的个人信息，并使用安全保护机制防止您的个人信息遭到恶意攻击。
      </p>
      <p>
        6.3我们会建立专门的信息安全部门、信息安全管理制度、数据安全流程保障您的个人信息安全。我们采取严格的数据使用和访问制度，确保只有授权人员才可访问您的个人信息，并适时对数据和技术进行安全审计。
      </p>
      <p class="bold">
        6.4尽管已经采取了上述合理有效措施，并已经遵守了相关法律规定要求的标准，但请您理解，由于技术的限制以及可能存在的各种恶意手段，在互联网行业，即便竭尽所能加强安全措施，也不可能始终保证信息百分之百的安全，我们将尽力确保您提供给我们的个人信息的安全性。
      </p>
      <p class="bold">
        6.5您知悉并理解，您接入我们的服务所用的平台和通讯网络，有可能因我们可控范围外的因素而出现问题。因此，我们强烈建议您采取积极措施保护个人信息的安全，包括但不限于不将自己的账号及相关个人信息透露给他人等。
      </p>
      <p>
        6.6我们会制定应急处理预案，并在发生用户信息安全事件时立即启动应急预案，努力阻止这些安全事件的影响和后果扩大。一旦发生用户信息安全事件(泄露、丢失)后，我们将按照法律法规的要求，及时向您告知:安全事件的基本情况和可能的影响、我们已经采取或将要采取的处置措施、您可自主防范和降低风险的建议、对您的补救措施。我们将及时将事件相关情况以推送通知、信函、短信及相关形式告知您，难以逐一告知时，我们会采取合理、有效的方式发布公告。同时，我们还将按照相关监管部门要求，上报用户信息安全事件的处置情况。
      </p>
      <p class="bold">七、管理您的个人信息</p>
      <p>
        我们非常重视您对个人信息的管理，并尽全力保护您对于您个人信息的查询、访问、修改、删除、撤回同意授权、注销账号、投诉举报的相关权利，以使您有能力保障您的隐私和信息安全。
      </p>
      <p>7.1 改变或撤回授权范围</p>
      <p>7.1.1改变或撤回敏感权限设置</p>
      <p>
        您可以在设备本身操作平台中关闭相关权限改变同意范围或撤回您的授权。撤回授权后我们将不再收集与这些权限相关信息。
      </p>
      <p>7.1.2 改变或撤回授权的信息处理</p>
      <p class="bold">
        特定的业务功能和服务将需要您的信息才能得以完成，当您撤回同意或授权后，我们无法继续为您提供撤回同意或授权所对应的功能和服务，也不再处理您相应的个人信息。但您撤回同意或授权的决定，不会影响我们此前基于您的授权而开展的个人信息处理。
      </p>
      <p class="bold">八、您的权利</p>
      <p>
        （一）您可以通过客服联系我们的方式查询和管理您的信息，我们会在符合法律法规要求的情形下响应您的请求。在以下情形中，您可以通过与客服联系向我们提出删除个人信息的请求：
      </p>
      <p>1、如果我们处理个人信息的行为违反法律法规；</p>
      <p>2、如果我们收集、使用您的个人信息，却未征得您的明确同意；</p>
      <p>3、如果我们处理个人信息的行为严重违反了与您的约定；</p>
      <p>4、如果我们的处理目的已实现、无法实现或者为实现处理目的不再必要；</p>
      <p>5、如果我们停止提供产品或者服务，或者保存期限已届满。</p>
      <p>
        若我们决定响应您的删除请求，我们还将同时尽可能通知从我们处获得您的个人信息的主体，并要求其及时删除（除非法律法规另有规定，或这些主体已独立获得您的授权）。
      </p>
      <p>
        当我们协助您删除相关信息后，因为适用的法律和安全技术限制，我们可能无法立即从备份系统中删除相应的信息，我们将安全地存储您的个人信息并限制对其的任何进一步的处理，直到备份可以清除或实现匿名化。
      </p>
      <p>（二）约束信息系统自动决策</p>
      <p>
        在某些业务功能中，我们可能仅依据算法在内的非人工自动决策机制做出决定。如果这些决定影响您的合法权益，您可以通过客服联系我们。
      </p>
      <p>（三）响应您的上述请求</p>
      <p>
        对于您或可能的您的监护人、近亲属及其他有权主体向我们提出上述请求，以及中华人民共和国法律与其他适用法律规定的您的相关个人信息权利，您可以通过客服联系我们或者发送电子邮件到jubao@shangjietech.com。
      </p>
      <p>
        为保障您的账户安全和您的个人信息安全，您向我们提出上述请求时，我们可能会先验证您的身份（如增加账户核验、要求您提供书面请求或其他合理方式），然后再处理您的请求。
      </p>
      <p>
        对于与您的身份不直接关联的信息、无合理理由重复申请的信息，或者需要过多技术手段（如需要开发新系统或从根本上改变现行惯例）、给他人合法权益带来风险或者不切实际的请求，我们可能会予以拒绝。
      </p>
      <p class="bold">九、未成年人保护</p>
      <p>
        我们非常重视未成年人的个人信息保护。在本次活动中我们推定您具有相应的民事行为能力，<span
          class="bold"
          >如您为未成年人，我们要求您请您的父母或其他监护人仔细阅读本政策，并在征得您的父母或其他监护人同意的前提下参与我们的活动或向我们提供信息。</span
        >
      </p>
      <p class="bold">
        如果您是14周岁以下儿童的，在您使用我们的服务前，您应确保在取得监护人的同意后，您才可在监护人的指导下使用我们的服务，确保您在使用我们的服务和进行交易时的安全。
      </p>
      <p class="bold">
        若您是儿童的父母或其他监护人，请您关注儿童是否是在取得您的授权同意之后使用我们的服务。
      </p>
      <p class="bold">
        对于收集到的未成年人个人信息，我们除遵守本隐私政策关于用户个人信息的约定外，还会严格遵循相关法律法规的要求。
      </p>
      <p>
        受制于现有技术和业务模式，我们很难主动识别未成年人的信息，如果未成年人的监护人发现存在未经授权而收集未成年人个人信息，监护人可通知我们予以删除，如我们自己发现前述情形，我们也会主动予以删除。
      </p>
      <p class="bold">十、联系我们</p>
      <p>
        如果您有任何问题、建议或投诉，请与我们联系，请致电
        <a href="tel:400-789-1195">400-789-1195</a>
        （周一至周五，8:30-17:30，法定节假日除外）或者发送电子邮件到jubao@shangjietech.com。为保障您的账户安全和您的个人信息安全，您向我们提出上述请求时，我们可能会先验证您的身份（如增加账户核验、要求您提供书面请求或其他合理方式），然后再处理您的请求。
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PrivacyAdultNew',
  data() {
    return {}
  },
  methods: {},
  // 进入页面时保存当前页url
  mounted() {
    this.$refs.content.scrollTop = 0
    // window.scrollTo(0, 0)
  }
}
</script>

<style lang="less" scoped>
.box {
  padding: 20px;
  font-size: 26px;
  line-height: 46px;
  color: #333;
  width: 100%;
  height: 100%;
  overflow: scroll;
  -webkit-overflow-scrolling: touch;
  .content {
    h2 {
      font-size: 30px;
      text-align: center;
      font-weight: bold;
      margin: 20px 0;
    }

    .bold {
      font-weight: bold;
    }

    p {
      text-indent: 2em;
    }
    a {
      color: #333;
    }
  }
}
</style>
